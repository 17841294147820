.homePage {
  font-family: "Verdana", Geneva, sans-serif;
  line-height: 1.6;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.homeContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  margin-bottom: 50px;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.homePage-image {
  flex: 1;
  max-width: 500px;
  margin-right: 20px;
  text-align: center; /* Center content */
}

.homePage-image img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin: 0 auto; /* Center image */
}

.homePage-description {
  flex: 1;
  text-align: left;
}

.homePage-description h2 {
  font-size: 24px;
  color: #25345e;
  font-weight: bold;
  margin-bottom: 20px;
}

.homePage-description p {
  font-size: 18px;
  color: #666;
  line-height: 1.8;
}

.homePage-search {
  font-family: "Verdana", Geneva, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.homePage-search p {
  font-family: "Verdana", Geneva, sans-serif;
  font-size: 24px;
  color: #25345e;
  line-height: 1.8;
}

input[type="text"] {
  font-family: "Verdana", Geneva, sans-serif;
  width: 40%;
  padding: 12px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

button {
  background-color: #0073e6;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 12px 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Verdana", Geneva, sans-serif;
}

button:hover {
  background-color: #005bb5;
}

.search-description {
  font-size: 16px;
  color: #25345e;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .homeContent {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
  }

  .homePage-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .homePage-description {
    text-align: center;
  }

  .homePage-description h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .homePage-description p {
    font-size: 16px;
  }

  .homePage-search {
    width: 100%;
    padding: 15px;
  }

  input[type="text"] {
    font-size: 14px;
    padding: 10px;
  }

  button {
    font-size: 16px;
    padding: 10px 20px;
  }
}
