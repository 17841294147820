.certificateDetails {
  font-family: "Georgia", serif;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
}

.certificateDetails h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.certificateDetails div {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.certificateDetails p {
  font-size: 16px;
  color: #444;
  margin: 10px 0;
}

.certificateDetails p:first-child {
  font-weight: bold;
}

.certificateDetails .not-found {
  text-align: center;
  font-size: 18px;
  color: #f00;
  margin-top: 20px;
}

.errorMessage p {
  color: red;
  font-weight: bold;
}
