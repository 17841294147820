/* appLayout.css */
.app {
  background-color: #f0f0f0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  min-height: 100vh; /* 确保至少占满整个视口高度 */
  display: flex;
  flex-direction: column; /* 确保内容是垂直排列的 */
  background-image: url("../assets/backgroundImage/bgImg.jpg");
  background-size: cover; /* Cover the entire background */
  background-position: center center;
}

.title {
  text-align: center;
  padding: 20px 0;
}
h1 {
  font-family: 'Georgia', serif; /* 使用古典风格的字体，你可以替换成其他古典字体 */
  font-size: 36px; /* 加大标题字体 */
  color: #f8f6f6; /* 更深的标题颜色，增加权重 */
  margin: 0; /* 移除标题的默认外边距 */
  padding: 0; /* 移除标题的默认内边距 */
  line-height: 1; /* 设置行高为 1，与 header 的高度一致 */
  text-transform: uppercase; /* 转换为大写字母 */
  letter-spacing: 2px; /* 增加字符间距 */
}

.app-header {
  background-color: #25345e; /* 深蓝色背景 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 更深的阴影，增加立体感 */
  height: auto; /* 移除固定高度，以适应内容 */
}

.menu {
  text-align: center;
  padding: 0 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0 auto; /* 居中对齐整个菜单 */
  display: flex;
  justify-content: center; /* 菜单项居中对齐 */
  align-items: center;
  max-width: 1200px; /* 限制最大宽度，适应大屏幕 */
}

.menu-list li {
  margin: 0 15px; /* 减少菜单项之间的间距 */
  padding: 10px 0; /* 增加触摸区域，提高可点击性 */
}

.menu-list li a {
  text-decoration: none;
  font-size: 18px; /* 微调字体大小，更显精致 */
  color: #ffffff; /* 白色文字 */
  padding: 10px 20px; /* 文字四周添加内边距 */
  border-radius: 5px; /* 添加圆角边框 */
  transition: all 0.3s ease;
  background-color: #25345e; /* 深蓝色背景 */
}

.menu-list li a:hover {
  color: #fff; 
  background-color: #1a2657; 
  transform: translateY(-2px); 
}

.app-content {
  flex: 1; 
}

.logo-item img {
  max-width: 180px; 
  height: auto;
  margin: 20px auto; 
}

.footer {
  position: relative; 
  bottom: 0; 
  width: 100%; 
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .app-header {
    text-align: center; /* Center-align the header content */
  }
  .menu {
    width: 100%; /* Ensure the menu takes full width */
    padding: 0; /* Remove additional padding */
  }
  .menu-list {
    flex-direction: column; /* Stack menu items vertically */
    align-items: center; /* Center-align items horizontally */
    justify-content: center; /* Center items vertically */
    width: 100%; /* Full width for the list */
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove any padding */
  }

  .menu-list li {
    width: 100%; 
    text-align: center;
  }

  .logo-item img {
    max-width: 120px; 
  }
}
