/* aboutUs.css */
.program-page {
  font-family: "Verdana", Geneva, sans-serif;
  color: #333333; /* Dark text for readability and elegance */
  padding: 20px;

}

.program-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Add space between each project */
}

.program-content {
  display: flex;
  align-items: center;
}

.program-image {
  width: 400px;
  margin-right: 20px;
}

.program-detail {
  background-color:rgba(255, 255, 255, 0.8); /* White background for content sections */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 5px; /* Slight rounding of corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.program-detail  h2 {
  color: #25345e; /* Slightly darker color for headers */
  border-bottom: 2px solid #eaeaea; /* Underline for section headers */
  padding-bottom: 10px;
  margin-bottom: 20px;
  
}

.program-detail  p{
  line-height: 1.6; /* Improved readability */
  text-align: justify; /* Justified text for a formal look */
  color: #666;
  font-size: 18px; /* 设置标题的字体大小 */
  font-weight: bold; /* 加粗标题 */
}

/* Responsive design for better viewing on different devices */
/* Responsive design for better viewing on different devices */
@media (max-width: 768px) {
  .program-page {
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .program-container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    align-items: flex-start; /* Align items to the start for a cleaner look */
  }

  .program-content {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .program-image {
    width: 100%; /* Full width images on smaller screens */
    margin-right: 0; /* Remove margin to fit the screen */
    margin-bottom: 10px; /* Add space below the image */
  }

  .program-detail {
    margin: 10px 0; /* Adjust margin for smaller screens */
    padding: 10px; /* Reduce padding for a more compact look */
  }

  .program-detail h2 {
    font-size: 1.2em; /* Reduce font size for headings */
  }

  .program-detail p {
    text-align: left; /* Left align text for better readability on smaller screens */
  }
}
