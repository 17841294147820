/* footer.css */

.footer {
    background-color: #333;
    width: 100%;
    color: #fff;
    padding: 40px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .footer-logo h1 {
    font-size: 24px;
  }
  
  .footer-logo p {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .footer-contact h2,
  .footer-social h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-contact p,
  .footer-social a {
    font-size: 14px;
    margin-top: 5px;
  }
  
  .footer-social a {
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .footer-social a:hover {
    color: #007bff;
  }
  
  .footer-bottom {
    text-align: center;
    background-color: #222;
    padding: 10px 0;
  }
  
  .footer-bottom p {
    font-size: 12px;
    margin: 0;
  }
  @media (max-width: 768px) {
    .footer-content {
      justify-content: center;
      text-align: center;
    }
  
    .footer-logo, .footer-contact, .footer-social {
      margin-bottom: 20px;
    }
  
    .footer-social a {
      margin-right: 0; /* Remove right margin for mobile */
    }
  }