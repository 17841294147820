/* aboutUs.css */
.about-us-page {
  font-family: "Verdana", Geneva, sans-serif;
  color: #333333; /* Dark text for readability and elegance */
  padding: 20px;
}

.company-introduction,
.company-history,
.contact-info,
.team-info {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* White background for content sections */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 5px; /* Slight rounding of corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.company-introduction h2,
.company-history h2,
.contact-info h2,
.team-info h2 {
  color: #25345e; /* Slightly darker color for headers */
  border-bottom: 2px solid #eaeaea; /* Underline for section headers */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.company-introduction p,
.company-history p,
.contact-info p {
  line-height: 1.6; /* Improved readability */
  text-align: justify; /* Justified text for a formal look */
  color: #666;
}

.team-member h3 {
  color: #25345e; /* Slightly darker color for team member names */
  font-weight: bold;
  margin-bottom: 10px;
}
.team-member ul {
  padding-left: 20px; /* Add some indentation for qualifications */
}

.team-member li {
  line-height: 1.6; /* Improved readability for qualifications */
  color: #666;
}

/* Responsive design for better viewing on different devices */
@media (max-width: 768px) {
  .about-us-page {
    padding: 10px;
  }

  .company-introduction,
  .company-history,
  .contact-info {
    margin: 10px 5px;
    padding: 15px;
  }
  .team-info {
    margin: 10px 5px;
    padding: 15px;
  }
}
