/* joinUs.css */

.joinUsPage {
  font-family: "Verdana", Geneva, sans-serif;
  padding: 50px;
  text-align: left;
}

.joinUsHeader {
  margin-bottom: 50px;
}

.joinUsHeader h1 {
  font-size: 40px;
  color: #25345e;
  margin-bottom: 20px;
}

.joinUsHeader p {
  font-size: 20px;
  color: white;
}

.joinUsContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px; 
}

.jobOpening {
  background-color:rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 600px;
  width: 400px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


.jobOpening h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.jobOpening p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.jobOpening a {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.jobOpening a:hover {
  background-color: #0056b3;
}

.email-address {
  text-decoration: underline; 
  color: #25345e; 
}

@media (max-width: 768px) {
  .joinUsPage {
    padding: 30px; /* 减小页面内边距 */
  }

  .joinUsHeader h1 {
    font-size: 24px; /* 减小标题字体大小 */
  }
  .joinUsHeader p {
    font-size: 18px;
  }

  .jobOpening h2 {
    font-size: 18px;
  }

  .jobOpening p {
    font-size: 14px;
  }

  .jobOpening {
    width: 100%; /* 让工作机会占满屏幕宽度 */
    margin-bottom: 20px; /* 增加工作机会之间的间距 */
    height: auto; /* 移除固定高度，以适应内容 */
  }
}